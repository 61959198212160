import { combineReducers } from "redux";
import {
  ADD_COMMENT_SUCCESS,
  SET_START_DATE,
  SET_END_DATE,
  LOGOUT,
  ADD_DATE_COMMENT,
} from "./actions";

// ✅ Initial state for comments reducer
const initialCommentsState = {
  commentsByItemId: {},
};

// ✅ Comments reducer
const commentsReducer = (state = initialCommentsState, action) => {
  switch (action.type) {
    case ADD_COMMENT_SUCCESS:
      const { itemId, comment } = action.payload;
      return {
        ...state,
        commentsByItemId: {
          ...state.commentsByItemId,
          [itemId]: [...(state.commentsByItemId[itemId] || []), comment],
        },
      };
    default:
      return state;
  }
};

// ✅ Initial state for date range reducer
const initialDateState = { dateRange: {}, commentsByItemId: {} };

const dateRangeReducer = (state = initialDateState, action) => {
  switch (action.type) {
    case SET_START_DATE:
      console.log("🛠 Reducer - Setting Start Date:", action.payload);
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          [action.payload.itemId]: {
            ...(state.dateRange[action.payload.itemId] || {}), // ✅ Fix for undefined state
            start: action.payload.startDate,
          },
        },
      };
    case SET_END_DATE:
      console.log("🛠 Reducer - Setting End Date:", action.payload);
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          [action.payload.itemId]: {
            ...(state.dateRange[action.payload.itemId] || {}), // ✅ Fix for undefined state
            end: action.payload.endDate,
          },
        },
      };
    case ADD_DATE_COMMENT:
      return {
        ...state,
        commentsByItemId: {
          ...state.commentsByItemId,
          [action.payload.itemId]: [
            ...(state.commentsByItemId[action.payload.itemId] || []),
            action.payload,
          ],
        },
      };
    default:
      return state;
  }
};

// ✅ Initial state for authentication
const authInitialState = {
  isLoggedIn: false,
};

// ✅ Auth reducer
const authReducer = (state = authInitialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};

// ✅ Initial state for hashtags
const initialHashtagsState = {
  hashtags: [],
  selectedHashtags: [],
};

// ✅ Hashtags reducer
const hashtagsReducer = (state = initialHashtagsState, action) => {
  switch (action.type) {
    case "SET_SELECTED_HASHTAGS":
      return {
        ...state,
        selectedHashtags: action.payload,
      };
    default:
      return state;
  }
};

// ✅ Combine all reducers properly
const rootReducer = combineReducers({
  comments: commentsReducer,
  auth: authReducer,
  dateRange: dateRangeReducer,
  hashtags: hashtagsReducer,
});

export default rootReducer;
