import React, { useState, useEffect } from "react";
import HashtagService from "../services/HashtagService";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import UserProfile from "../../Login/UserProfile";
import "../styles/SettingPanel.css";
function SettingsPage() {
  const [organizations, setOrganizations] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [orgName, setOrgName] = useState("");
  const [teamName, setTeamName] = useState("");
  const [newHashtag, setNewHashtag] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [hashtags, setHashtags] = useState([]);
  const [error, setError] = useState("");
  const [editingTeam, setEditingTeam] = useState(null);
  const [updatedTeamName, setUpdatedTeamName] = useState("");
  const [editingHashtag, setEditingHashtag] = useState(null);
  const [updatedHashtag, setUpdatedHashtag] = useState("");
  const [updatedDescription, setUpdatedDescription] = useState("");
  const [refreshTrigger, setRefreshTrigger] = useState(false); // Added to force refresh
  const [loadingTeams, setLoadingTeams] = useState(false);
  const [selectedHashtagId, setSelectedHashtagId] = useState("");
  const [selectedTeams, setSelectedTeams] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const orgs = await HashtagService.fetchOrganizations();
        const allTeams = await HashtagService.fetchAllTeams();
        const allHashtags = await HashtagService.fetchAllHashtags();
        setOrganizations(orgs);
        setTeams(allTeams);
        setHashtags(allHashtags);
      } catch (err) {
        setError("Error fetching initial data.");
      }
    };
    fetchData();
  }, []);
  // Fetch organizations on component mount

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const orgs = await HashtagService.fetchOrganizations();
        setOrganizations(orgs);
      } catch (error) {
        console.error("Error fetching organizations:", error);
        setError("Failed to fetch organizations.");
      }
    };
    fetchOrganizations();
  }, []);
  useEffect(() => {
    const fetchTeams = async () => {
      if (!selectedOrg) return;

      console.log(`[INFO] Fetching teams for organization ID: ${selectedOrg}`);

      try {
        const fetchedTeams = await HashtagService.fetchTeamsByOrg(selectedOrg);
        console.log("[SUCCESS] Teams fetched successfully:", fetchedTeams);
        setTeams(fetchedTeams);
      } catch (error) {
        console.error("[ERROR] Fetching teams failed:", error.message);
        setError("Failed to fetch teams.");
      }
    };

    fetchTeams();
  }, [selectedOrg, refreshTrigger]); // ✅ Refresh UI after team update
  useEffect(() => {
    if (selectedOrg) {
      console.log("[INFO] Refreshing team list...");
      HashtagService.fetchTeamsByOrg(selectedOrg) // ✅ Corrected function call
        .then((teams) => {
          console.log("[DEBUG] Teams received in UI:", teams);
          setTeams(teams);
        })
        .catch(console.error);
    }
  }, [selectedOrg, refreshTrigger]); // ✅ Ensure state updates correctly

  // Fetch teams whenever the selected organization or refreshTrigger changes
  // useEffect(() => {
  //   const fetchTeams = async () => {
  //     if (!selectedOrg) return;
  //     try {
  //       const fetchedTeams = await HashtagService.fetchTeamsByOrg(selectedOrg);
  //       setTeams(fetchedTeams);
  //     } catch (error) {
  //       console.error("Error fetching teams:", error);
  //       setError("Failed to fetch teams.");
  //     }
  //   };
  //   fetchTeams();
  // }, [selectedOrg]);
  // Depend on refreshTrigger for forced refresh
  // Fetch hashtags based on selected team
  useEffect(() => {
    const fetchHashtags = async () => {
      if (selectedTeam) {
        try {
          const fetchedHashtags =
            selectedTeam === "uncategorized"
              ? await HashtagService.fetchUncategorizedHashtags()
              : await HashtagService.fetchHashtagsByTeam(selectedTeam);
          setHashtags(fetchedHashtags);
        } catch (error) {
          console.error("Error fetching hashtags:", error);
          setError("Failed to fetch hashtags.");
        }
      } else {
        setHashtags([]);
      }
    };
    fetchHashtags();
  }, [selectedTeam]);

  // Function to create a new organization
  const createOrganization = async () => {
    if (!orgName.trim()) {
      setError("Organization name cannot be empty.");
      return;
    }
    try {
      const newOrg = await HashtagService.createOrganization(orgName);
      setOrganizations((prev) => [...prev, newOrg]);
      setOrgName("");
      setError("");
    } catch (error) {
      console.error("Failed to create organization:", error);
      setError("Failed to create organization.");
    }
  };

  // Function to add a new team
  const addTeam = async () => {
    if (!teamName.trim()) {
      setError("Team name cannot be empty.");
      return;
    }
    try {
      const newTeam = await HashtagService.addTeamToOrganization(
        selectedOrg,
        teamName
      );
      setTeams((prev) => [...prev, newTeam]);
      setTeamName("");
      setError("");
    } catch (error) {
      console.error("Error adding team:", error);
      setError("Failed to add team.");
    }
  };

  // Update an existing team
  const updateTeam = async () => {
    if (!updatedTeamName.trim()) {
      setError("Updated team name cannot be empty.");
      return;
    }

    try {
      console.log(
        `[INFO] Updating team: ${editingTeam} to name: ${updatedTeamName}`
      );

      const updated = await HashtagService.updateTeamName(
        editingTeam,
        updatedTeamName
      );

      console.log("[SUCCESS] Team name updated:", updated);

      // ✅ Update teams list with the new name
      setTeams((prev) =>
        prev.map((team) => (team._id === editingTeam ? updated : team))
      );

      // ✅ Reset editing state
      setEditingTeam(null);
      setUpdatedTeamName("");

      // ✅ Force UI refresh
      setRefreshTrigger((prev) => !prev);
    } catch (error) {
      console.error("[ERROR] Failed to update team name:", error.message);
      setError("Failed to update team name.");
    }
  };

  // const updateTeam = async () => {
  //   if (!updatedTeamName.trim()) {
  //     setError("Updated team name cannot be empty.");
  //     return;
  //   }

  //   try {
  //     const updated = await HashtagService.updateTeamName(
  //       editingTeam,
  //       updatedTeamName
  //     );

  //     setTeams((prev) =>
  //       prev.map((team) => (team._id === editingTeam ? updated : team))
  //     );
  //     setEditingTeam(null);
  //     setUpdatedTeamName("");
  //   } catch (error) {
  //     console.error("Error updating team:", error.message);
  //     setError("Failed to update team name.");
  //   }
  // };
  // Add a new hashtag to the selected team
  const handleAddHashtag = async () => {
    if (!selectedTeam) {
      setError("Please select a team before adding a hashtag.");
      return;
    }
    const hashtagData = { tag: newHashtag, description: newDescription };
    try {
      const addedHashtag = await HashtagService.addHashtagToTeam(
        selectedTeam,
        hashtagData
      );
      setHashtags((prev) => [...prev, addedHashtag]);
      setNewHashtag("");
      setNewDescription("");
      setError("");
    } catch (error) {
      console.error("Failed to add hashtag:", error);
      setError("Failed to add hashtag to team.");
    }
  };

  // Update an existing hashtag
  const updateHashtag = async () => {
    try {
      const updated = await HashtagService.updateHashtag(editingHashtag, {
        tag: updatedHashtag,
        description: updatedDescription,
      });
      setHashtags((prev) =>
        prev.map((hashtag) =>
          hashtag._id === editingHashtag ? updated : hashtag
        )
      );
      setEditingHashtag(null);
      setUpdatedHashtag("");
      setUpdatedDescription("");
      setError("");
    } catch (error) {
      console.error("Failed to update hashtag:", error);
      setError("Failed to update hashtag.");
    }
  };
  const assignHashtagToTeams = async () => {
    if (!selectedHashtagId || selectedTeams.length === 0) {
      console.warn("Select a hashtag and at least one team.");
      setError("Please select a hashtag and at least one team.");
      return;
    }

    try {
      console.log("Reassigning hashtag:", selectedHashtagId);

      const response = await fetch(
        `/api/hashtags/${selectedHashtagId}/reassign`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            newTeamIds: selectedTeams, // new teams selected in UI
            removeFromTeamId: selectedTeam, // current team selected in UI
          }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to reassign hashtag:", errorText);
        setError("Failed to reassign hashtag.");
        return;
      }

      const result = await response.json();
      console.log("Hashtag reassigned successfully:", result);

      alert("Hashtag reassigned successfully.");
      setRefreshTrigger((prev) => !prev); // refresh UI
    } catch (error) {
      console.error("Error during reassignment:", error.message || error);
      setError("Error reassigning hashtag.");
    }
  };

  // const assignHashtagToTeams = async () => {
  //   if (!selectedHashtagId || selectedTeams.length === 0) {
  //     console.warn("Select a hashtag and at least one team.");
  //     setError("Please select a hashtag and at least one team.");
  //     return;
  //   }

  //   try {
  //     console.log(
  //       "Assigning hashtag:",
  //       selectedHashtagId,
  //       "to teams:",
  //       selectedTeams
  //     );

  //     for (const teamId of selectedTeams) {
  //       try {
  //         const response = await HashtagService.assignHashtagToTeam(teamId, {
  //           hashtagId: selectedHashtagId,
  //         });

  //         if (response && response.message) {
  //           console.log(
  //             `Successfully assigned hashtag ${selectedHashtagId} to team ${teamId}:`,
  //             response
  //           );
  //         } else {
  //           console.error(
  //             `Unexpected response when assigning hashtag ${selectedHashtagId} to team ${teamId}:`,
  //             response
  //           );
  //         }
  //       } catch (err) {
  //         console.error(
  //           `Failed to assign hashtag ${selectedHashtagId} to team ${teamId}:`,
  //           err.message || err
  //         );
  //       }
  //     }

  //     alert("Hashtag assigned successfully.");
  //   } catch (error) {
  //     console.error(
  //       "Error assigning hashtag to teams:",
  //       error.message || error
  //     );
  //     setError("Error assigning hashtag to teams.");
  //   }
  // };
  return (
    <div>
      <div className="top-navbar">
        <Link to="/">
          <div className="logo">
            <img src="/logo.png" alt="Logo" />
          </div>
        </Link>
        <Link to="/" className="app-title-link">
          <div className="app-title">Pioneer</div>
        </Link>
        <div>
          <UserProfile />
        </div>
      </div>
      <div style={{ backgroundColor: "white", width: "100vw" }}>
        <h2>Create Organization</h2>
        <input
          value={orgName}
          onChange={(e) => setOrgName(e.target.value)}
          placeholder="Organization Name"
        />
        <button onClick={createOrganization}>Add Organization</button>
        <h2>Add Team to Organization</h2>
        <select
          value={selectedOrg}
          onChange={(e) => setSelectedOrg(e.target.value)}
        >
          <option value="">Select Organization</option>
          {organizations.map((org) => (
            <option key={org._id} value={org._id}>
              {org.name}
            </option>
          ))}
        </select>
        <input
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
          placeholder="Team Name"
        />
        <button onClick={addTeam} disabled={!selectedOrg}>
          Add Team
        </button>
        {!selectedOrg && (
          <p style={{ color: "green", fontStyle: "normal" }}>
            * Please select an organization.
          </p>
        )}
        <h2>Select Team to View and Edit Hashtags</h2>
        <select
          value={selectedTeam}
          onChange={(e) => setSelectedTeam(e.target.value)}
        >
          <option value="">Select Team</option>
          {teams.map((team) => (
            <option key={team._id} value={team._id}>
              {team.name}
            </option>
          ))}
        </select>
        {selectedTeam && (
          <>
            <h2>Edit Selected Team</h2>
            <button
              onClick={() => {
                setEditingTeam(selectedTeam);
                const teamToEdit = teams.find(
                  (team) => team._id === selectedTeam
                );
                setUpdatedTeamName(teamToEdit ? teamToEdit.name : "");
              }}
            >
              Edit Team
            </button>
          </>
        )}
        {editingTeam && (
          <div>
            <input
              value={updatedTeamName}
              onChange={(e) => setUpdatedTeamName(e.target.value)}
              placeholder="Update Team Name"
            />
            <button onClick={updateTeam}>Save</button>
            <button onClick={() => setEditingTeam(null)}>Cancel</button>
          </div>
        )}

        <h2>Hashtags in Selected Team</h2>
        <select
          value={editingHashtag}
          onChange={(e) => {
            const selectedHashtagId = e.target.value;
            const selectedHashtag = hashtags.find(
              (hashtag) => hashtag._id === selectedHashtagId
            );
            setEditingHashtag(selectedHashtagId);
            setUpdatedHashtag(selectedHashtag ? selectedHashtag.tag : "");
            setUpdatedDescription(
              selectedHashtag ? selectedHashtag.description : ""
            );
          }}
        >
          <option value="">Select Hashtag to Edit</option>
          {hashtags.map((hashtag) => (
            <option key={hashtag._id} value={hashtag._id}>
              {hashtag.tag}
            </option>
          ))}
        </select>

        {editingHashtag && (
          <div>
            <input
              value={updatedHashtag}
              onChange={(e) => setUpdatedHashtag(e.target.value)}
              placeholder="Update Hashtag"
            />
            <input
              value={updatedDescription}
              onChange={(e) => setUpdatedDescription(e.target.value)}
              placeholder="Update Description"
            />
            <button onClick={updateHashtag}>Save</button>
            <button onClick={() => setEditingHashtag(null)}>Cancel</button>
          </div>
        )}
        <h2>Add Hashtag to Team</h2>
        <input
          value={newHashtag}
          onChange={(e) => setNewHashtag(e.target.value)}
          placeholder="New Hashtag"
        />
        <input
          value={newDescription}
          onChange={(e) => setNewDescription(e.target.value)}
          placeholder="Description"
        />
        <button onClick={handleAddHashtag} disabled={!selectedTeam}>
          Add Hashtag
        </button>

        <h2>Assign Hashtag to Teams</h2>
        <p style={{ color: "green", fontStyle: "italic" }}>
          * Please select multiple teams by clicking Shift + Down Arrow / Up
          Arrow.
        </p>
        <select
          value={selectedHashtagId}
          onChange={(e) => setSelectedHashtagId(e.target.value)}
        >
          <option value="">Select Hashtag</option>
          {hashtags.map((hashtag) => (
            <option key={hashtag._id} value={hashtag._id}>
              {hashtag.tag}
            </option>
          ))}
        </select>
        <select
          multiple
          value={selectedTeams}
          onChange={(e) => {
            const selectedOptions = Array.from(
              e.target.selectedOptions,
              (option) => option.value
            );
            setSelectedTeams(selectedOptions);
          }}
        >
          {teams.map((team) => (
            <option key={team._id} value={team._id}>
              {team.name}
            </option>
          ))}
        </select>
        <button className="custom-button" onClick={assignHashtagToTeams}>
          Assign Hashtag
        </button>
      </div>
    </div>
  );
}

export default SettingsPage;
