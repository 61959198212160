import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import imageCompression from "browser-image-compression";
import LazyLoad from "react-lazyload";
import "./CommentForm.css";
import { addCommentSuccess } from "../../redux/actions";

const CommentForm = React.memo(({ itemId, onCommentSubmitted }) => {
  const [manualComment, setManualComment] = useState("");
  const [submittedDates, setSubmittedDates] = useState({
    start: null,
    end: null,
  });
  const dispatch = useDispatch();

  const dateRange = useSelector(
    (state) => state.comments.dateRange || { start: null, end: null }
  );

  const commentsFromStore = useSelector(
    (state) => state.comments[itemId] || []
  );

  // ✅ Format date as DD/MM/YYYY
  const formatDate = (date) => {
    const d = new Date(date);
    if (isNaN(d.getTime())) return "Invalid Date";
    return d.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  // ✅ Submit an automatic comment for date changes
  const submitAutoComment = async (type, date) => {
    const formattedDate = formatDate(date);
    const commentText = `${
      type === "start" ? "Start" : "End"
    } Date selected : ${formattedDate}`;
    console.log("📌 Auto-comment submitting:", commentText);
    await submitComment(commentText);
  };

  // ✅ Submit comment to the backend
  const submitComment = async (commentText) => {
    if (!commentText.trim()) return;

    const username = localStorage.getItem("username") || "Anonymous";
    const commentPayload = {
      text: commentText,
      postedBy: username,
    };

    try {
      const response = await fetch(`/api/items/${itemId}/comments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(commentPayload),
      });

      if (response.ok) {
        const newComment = await response.json();
        dispatch(addCommentSuccess(itemId, newComment));
        if (onCommentSubmitted) onCommentSubmitted(newComment);
      } else {
        console.error("Failed to submit comment.");
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  // ✅ Handle changes in start date
  useEffect(() => {
    const startDate = dateRange.start ? new Date(dateRange.start) : null;

    if (
      startDate &&
      submittedDates.start?.toISOString?.() !== startDate.toISOString() &&
      !commentsFromStore.some(
        (comment) =>
          comment.text === `Start Date selected : ${formatDate(startDate)}`
      )
    ) {
      setSubmittedDates((prev) => ({ ...prev, start: startDate }));
      submitAutoComment("start", startDate);
    }
  }, [dateRange.start, submittedDates.start, commentsFromStore]);

  // ✅ Handle changes in end date
  useEffect(() => {
    const endDate = dateRange.end ? new Date(dateRange.end) : null;

    if (
      endDate &&
      submittedDates.end?.toISOString?.() !== endDate.toISOString() &&
      !commentsFromStore.some(
        (comment) =>
          comment.text === `End Date selected : ${formatDate(endDate)}`
      )
    ) {
      setSubmittedDates((prev) => ({ ...prev, end: endDate }));
      submitAutoComment("end", endDate);
    }
  }, [dateRange.end, submittedDates.end, commentsFromStore]);

  // ✅ Handle manual comment submit
  const handleManualSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (manualComment.trim()) {
        submitComment(manualComment.trim());
        setManualComment("");
      }
    },
    [manualComment]
  );

  // ✅ Handle image upload from quill
  const handleImageUpload = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error("Error compressing the image:", error);
    }
  };

  return (
    <form className="comment-form" onSubmit={handleManualSubmit}>
      <ReactQuill
        value={manualComment}
        onChange={setManualComment}
        placeholder="Add a comment"
        modules={{
          toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image", "video"],
            ["clean"],
          ],
        }}
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "link",
          "image",
          "video",
        ]}
        className="react-quill-container comment-quill"
      />
      <LazyLoad height={200} offset={100}>
        <button className="button-fullwidth" type="submit">
          Submit
        </button>
      </LazyLoad>
    </form>
  );
});

export default CommentForm;

// import React, { useState, useEffect, useCallback } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import imageCompression from "browser-image-compression";
// import LazyLoad from "react-lazyload";
// import "./CommentForm.css";
// import { addCommentSuccess } from "../../redux/actions";

// const CommentForm = React.memo(({ itemId, onCommentSubmitted }) => {
//   const [manualComment, setManualComment] = useState("");
//   const [submittedDates, setSubmittedDates] = useState({
//     start: null,
//     end: null,
//   });
//   const dispatch = useDispatch();

//   const dateRange = useSelector(
//     (state) => state.comments.dateRange || { start: null, end: null }
//   );

//   const commentsFromStore = useSelector(
//     (state) => state.comments[itemId] || []
//   );
//   // ✅ Better formatter: DD/MM/YYYY
//   const formatDate = (date) => {
//     const d = new Date(date);
//     if (isNaN(d.getTime())) return "Invalid Date";
//     return d.toLocaleDateString("en-GB", {
//       day: "2-digit",
//       month: "2-digit",
//       year: "numeric",
//     });
//   };

//   // Helper function to format dates
//   // const formatDate = (date) => {
//   //   return new Date(date).toLocaleDateString("en-GB", {
//   //     year: "numeric",
//   //     month: "2-digit",
//   //     day: "2-digit",
//   //   });
//   // };

//   // Submit an automatic comment for date changes
//   const submitAutoComment = async (type, date) => {
//     const formattedDate = formatDate(date);
//     const commentText = `${
//       type === "start" ? "Start" : "End"
//     } Date selected : ${formattedDate}`;
//     await submitComment(commentText);
//   };

//   // Submit comment to the backend
//   const submitComment = async (commentText) => {
//     if (!commentText.trim()) return;

//     const username = localStorage.getItem("username") || "Anonymous";
//     const commentPayload = {
//       text: commentText,
//       postedBy: username,
//     };

//     try {
//       const response = await fetch(`/api/items/${itemId}/comments`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(commentPayload),
//       });

//       if (response.ok) {
//         const newComment = await response.json();
//         dispatch(addCommentSuccess(itemId, newComment));
//         if (onCommentSubmitted) onCommentSubmitted(newComment);
//       } else {
//         console.error("Failed to submit comment.");
//       }
//     } catch (error) {
//       console.error("Error submitting comment:", error);
//     }
//   };

//   // Handle changes in start date
//   useEffect(() => {
//     if (
//       dateRange.start &&
//       submittedDates.start !== dateRange.start &&
//       !commentsFromStore.some(
//         (comment) =>
//           comment.text ===
//           `Start Date selected : ${formatDate(dateRange.start)}`
//       )
//     ) {
//       setSubmittedDates((prev) => ({ ...prev, start: dateRange.start }));
//       submitAutoComment("start", dateRange.start);
//     }
//   }, [dateRange.start, submittedDates.start, commentsFromStore]);

//   // Handle changes in end date
//   useEffect(() => {
//     if (
//       dateRange.end &&
//       submittedDates.end !== dateRange.end &&
//       !commentsFromStore.some(
//         (comment) =>
//           comment.text === `End Date selected : ${formatDate(dateRange.end)}`
//       )
//     ) {
//       setSubmittedDates((prev) => ({ ...prev, end: dateRange.end }));
//       submitAutoComment("end", dateRange.end);
//     }
//   }, [dateRange.end, submittedDates.end, commentsFromStore]);

//   const handleManualSubmit = useCallback(
//     (event) => {
//       event.preventDefault();
//       if (manualComment.trim()) {
//         submitComment(manualComment.trim());
//         setManualComment("");
//       }
//     },
//     [manualComment]
//   );

//   //image
//   const handleImageUpload = async (file) => {
//     const options = {
//       maxSizeMB: 1, // Max size in MB
//       maxWidthOrHeight: 1024, // Max width or height in pixels
//       useWebWorker: true, // Use multi-threading for better performance
//     };
//     try {
//       const compressedFile = await imageCompression(file, options);
//       return compressedFile; // Return compressed file to be used
//     } catch (error) {
//       console.error("Error compressing the image:", error);
//     }
//   };

//   return (
//     <form className="comment-form" onSubmit={handleManualSubmit}>
//       <ReactQuill
//         value={manualComment}
//         onChange={setManualComment}
//         placeholder="Add a comment"
//         modules={{
//           toolbar: [
//             [{ header: "1" }, { header: "2" }, { font: [] }],
//             [{ size: [] }],
//             ["bold", "italic", "underline", "strike", "blockquote"],
//             [{ list: "ordered" }, { list: "bullet" }],
//             ["link", "image", "video"],
//             ["clean"],
//           ],
//         }}
//         formats={[
//           "header",
//           "bold",
//           "italic",
//           "underline",
//           "strike",
//           "blockquote",
//           "list",
//           "bullet",
//           "link",
//           "image",
//           "video",
//         ]}
//         className="react-quill-container"
//       />
//       <LazyLoad height={200} offset={100}>
//         <button className="button-fullwidth" type="submit">
//           Submit
//         </button>
//       </LazyLoad>
//     </form>
//   );
// });

// export default CommentForm;
