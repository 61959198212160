export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";
export const LOGOUT = "LOGOUT"; // Define and export the LOGOUT action type
export const SET_SELECTED_HASHTAGS = "SET_SELECTED_HASHTAGS";
export const ADD_DATE_COMMENT = "ADD_DATE_COMMENT";
// Action
export const addCommentSuccess = (itemId, comment) => ({
  type: ADD_COMMENT_SUCCESS,
  payload: { itemId, comment },
});

// Thunk action creator for logout
export const logoutAction = () => {
  return (dispatch) => {
    localStorage.removeItem("isUserLoggedIn"); // Perform synchronous logout operations
    dispatch({ type: LOGOUT });
  };
};

// Action creator for setting the start date

export const setSelectedHashtags = (hashtags) => ({
  type: SET_SELECTED_HASHTAGS,
  payload: hashtags,
});

export const setStartDate = (itemId, startDate) => ({
  type: SET_START_DATE,
  payload: { itemId, startDate },
});

export const setEndDate = (itemId, endDate) => ({
  type: SET_END_DATE,
  payload: { itemId, endDate },
});

export const addDateComment = (itemId, text) => ({
  type: ADD_DATE_COMMENT,
  payload: { itemId, text, postedAt: new Date() },
});
