import React, { useState, useEffect } from "react";
import "../styles/HashtagAndItemManager.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import HashtagService from "../services/HashtagService";

function HashtagManager({
  setSelectedTasks,
  selectedHashtags,
  setSelectedHashtags,
}) {
  const uncategorizedHashtagId = process.env.REACT_APP_UNCATEGORIZED_HASHTAG_ID;
  const [hashtags, setHashtags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [orgs, setOrgs] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [uiSelectedHashtags, setUiSelectedHashtags] = useState([]);

  // ✅ Load stored values correctly after refresh
  useEffect(() => {
    const savedOrg = localStorage.getItem("selectedOrg");
    const savedTeam = localStorage.getItem("selectedTeam");
    const savedHashtags = JSON.parse(
      localStorage.getItem("uiSelectedHashtags") || "[]"
    );

    if (savedOrg) setSelectedOrg(savedOrg);
    if (savedTeam) setSelectedTeam(savedTeam);
    if (savedHashtags.length > 0) {
      setUiSelectedHashtags(savedHashtags);
      setSelectedHashtags(savedHashtags);
    }
  }, []);

  // ✅ Fetch Organizations and Teams
  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        setLoading(true);
        const orgData = await HashtagService.fetchOrganizations();
        setOrgs(orgData);

        const savedOrg = localStorage.getItem("selectedOrg");
        if (savedOrg && orgData.some((org) => org._id === savedOrg)) {
          setSelectedOrg(savedOrg);
          const teamData = await HashtagService.fetchTeamsByOrg(savedOrg);
          setTeams(teamData);

          const savedTeam = localStorage.getItem("selectedTeam");
          if (savedTeam && teamData.some((team) => team._id === savedTeam)) {
            setSelectedTeam(savedTeam);
            fetchHashtags(savedTeam);
          }
        }
      } catch (err) {
        console.error("Error fetching organizations:", err);
        setError("Failed to fetch organizations.");
      } finally {
        setLoading(false);
      }
    };
    fetchOrganizations();
  }, []);

  // ✅ Fetch Hashtags for Selected Team
  const fetchHashtags = async (teamId) => {
    try {
      setLoading(true);
      let teamHashtags =
        teamId === "uncategorized"
          ? await HashtagService.fetchUncategorizedHashtags()
          : await HashtagService.fetchHashtagsByTeam(teamId);

      setHashtags(teamHashtags);

      // ✅ Keep previously selected hashtags
      const savedHashtags = JSON.parse(
        localStorage.getItem("uiSelectedHashtags") || "[]"
      );
      if (savedHashtags.length > 0) {
        setUiSelectedHashtags(savedHashtags);
        setSelectedHashtags(savedHashtags);
      }
    } catch (err) {
      console.error("Error fetching hashtags:", err);
      setError("Failed to fetch hashtags.");
    } finally {
      setLoading(false);
    }
  };

  // ✅ Persist selected hashtags in local storage
  useEffect(() => {
    localStorage.setItem(
      "uiSelectedHashtags",
      JSON.stringify(uiSelectedHashtags)
    );
    setSelectedHashtags(uiSelectedHashtags);
  }, [uiSelectedHashtags]);

  const handleOrgSelection = async (event) => {
    const orgId = event.target.value;
    setSelectedOrg(orgId);
    localStorage.setItem("selectedOrg", orgId);
    setSelectedTeam(null);
    localStorage.removeItem("selectedTeam");
    setHashtags([]);

    try {
      setLoading(true);
      const teamData = await HashtagService.fetchTeamsByOrg(orgId);
      setTeams(teamData);
    } catch (err) {
      console.error("Error fetching teams:", err);
      setError("Failed to fetch teams.");
    } finally {
      setLoading(false);
    }
  };

  const handleTeamSelection = async (event) => {
    const teamId = event.target.value;
    setSelectedTeam(teamId);
    localStorage.setItem("selectedTeam", teamId);
    fetchHashtags(teamId);
  };

  // ✅ Hashtag Selection Logic
  const handleSelectHashtag = (hashtag) => {
    setUiSelectedHashtags((prev) => {
      const exists = prev.some((h) => h._id === hashtag._id);
      let updatedHashtags;

      if (exists) {
        updatedHashtags = prev.filter((h) => h._id !== hashtag._id);
      } else {
        updatedHashtags = [
          ...prev.filter((h) => h._id !== uncategorizedHashtagId),
          hashtag,
        ];
      }

      setSelectedHashtags(updatedHashtags);

      return updatedHashtags.length > 0
        ? updatedHashtags
        : [{ _id: uncategorizedHashtagId, tag: "Uncategorized" }];
    });
  };

  return (
    <>
      <div className="org-selector-container">
        <div className="org-team-wrapper">
          <div className="org-selector">
            <label>Org:</label>
            <select
              id="org-select"
              value={selectedOrg || ""}
              onChange={handleOrgSelection}
            >
              <option value="">Select Organization</option>
              {orgs.map((org) => (
                <option key={org._id} value={org._id}>
                  {org.name}
                </option>
              ))}
            </select>
          </div>
          <div className="team-selector">
            <label>Team:</label>
            <select
              id="team-select"
              value={selectedTeam || ""}
              onChange={handleTeamSelection}
              disabled={!selectedOrg}
            >
              <option value="">Select Team</option>
              {teams.map((team) => (
                <option key={team._id} value={team._id}>
                  {team.name}
                </option>
              ))}
            </select>
          </div>

          {/* ✅ Settings Icon (Opens /settings in new tab) */}
          <button
            className="settings-button"
            onClick={() => window.open("/settings", "_blank")}
          >
            <FontAwesomeIcon icon={faCog} />
          </button>
        </div>
      </div>

      <h3 className="hastag-heading">Hashtags</h3>
      <div className="hashtag-manager-container">
        {loading && <p>Loading...</p>}
        {error && <p className="error">{error}</p>}
        <ul className="hashtag-list">
          {hashtags.map((hashtag) => (
            <li
              key={hashtag._id}
              className={`hashtag-item ${
                selectedHashtags.some((h) => h._id === hashtag._id)
                  ? "selected"
                  : ""
              }`}
              onClick={() => handleSelectHashtag(hashtag)}
            >
              {hashtag.tag}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default HashtagManager;
