import React, { useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap

const ResetPassword = () => {
  const { email } = useParams(); // Get email from URL params
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/resetpassword", {
        email,
        otp,
        newPassword,
      });
      setMessage(response.data.message);
      setError("");

      // Redirect to login after a few seconds
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (err) {
      setError("Error: " + (err.response?.data?.message || "Server error."));
      setMessage("");
    }
  };

  const wrapperStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    background: "transparent",
  };

  const cardStyle = {
    width: "400px",
    borderRadius: "15px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "30px",
    backgroundColor: "white",
    margin: "580px",
  };

  const inputStyle = {
    height: "50px", // ✅ Equal height for all input fields
    width: "100%", // ✅ Ensures same width
    fontSize: "1em",
  };

  const buttonStyle = {
    height: "50px", // ✅ Same height as input fields
    width: "100%", // ✅ Same width as input fields
    backgroundColor: "#007bff",
    borderColor: "#007bff",
    fontSize: "1em",
  };

  return (
    <div style={wrapperStyle}>
      <div style={cardStyle}>
        <h3 className="text-center mb-4">Reset Password</h3>
        {message && <p className="text-success text-center">{message}</p>}
        {error && <p className="text-danger text-center">{error}</p>}
        <form onSubmit={handleResetPassword}>
          <div className="mb-3">
            <label className="form-label">OTP</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
              style={inputStyle}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">New Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              style={inputStyle}
            />
          </div>
          <button type="submit" className="btn btn-primary" style={buttonStyle}>
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;

// import React, { useState, useEffect } from "react";
// import { Alert, Button, Card, Form } from "react-bootstrap";
// import { useParams, useNavigate } from "react-router-dom";
// import axios from "axios";

// const ResetPassword = () => {
//   const { token } = useParams(); // Get token from URL params
//   const navigate = useNavigate(); // To navigate to another route
//   const [isVerified, setIsVerified] = useState(false); // Track if the token is verified
//   const [verificationError, setVerificationError] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [newPassword, setNewPassword] = useState(""); // State for new password
//   const [passwordSuccess, setPasswordSuccess] = useState(""); // State for success message
//   const [passwordError, setPasswordError] = useState(""); // State for error message

//   useEffect(() => {
//     const verifyToken = async () => {
//       try {
//         // Verify token with backend API
//         const response = await axios.get(`/api/verifytoken/${token}`);
//         if (response.data.verified) {
//           setIsVerified(true); // Token is verified
//         }
//       } catch (error) {
//         setVerificationError("Invalid or expired reset link.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     verifyToken();
//   }, [token]);

//   // Function to handle password reset
//   const handleResetPassword = async (e) => {
//     e.preventDefault();
//     setPasswordError(""); // Clear previous error
//     setPasswordSuccess(""); // Clear previous success

//     try {
//       const response = await axios.post(`/api/resetpassword/${token}`, {
//         password: newPassword,
//       });

//       if (response.data.message === "Password has been reset successfully") {
//         setPasswordSuccess("Password has been reset successfully.");

//         // Redirect to login page after 3 seconds
//         setTimeout(() => {
//           navigate("/login");
//         }, 3000);
//       } else {
//         setPasswordError("Failed to reset password.");
//       }
//     } catch (error) {
//       setPasswordError("Error resetting password. Please try again.");
//     }
//   };

//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         height: "100vh",
//       }}
//     >
//       <Card
//         style={{
//           width: "400px",
//           padding: "20px",
//           textAlign: "center",
//           margin: "580px",
//         }}
//       >
//         {loading ? (
//           <p>Verifying reset link...</p>
//         ) : isVerified ? (
//           <>
//             <Alert variant="success">
//               Verified! You can now reset your password.
//             </Alert>

//             {/* Reset Password Form */}
//             <Form onSubmit={handleResetPassword}>
//               <Form.Group controlId="formNewPassword">
//                 <Form.Label>New Password</Form.Label>
//                 <Form.Control
//                   type="password"
//                   placeholder="Enter your new password"
//                   value={newPassword}
//                   onChange={(e) => setNewPassword(e.target.value)}
//                   required
//                 />
//               </Form.Group>
//               <Button type="submit" className="mt-3">
//                 Reset Password
//               </Button>
//             </Form>

//             {passwordSuccess && (
//               <Alert variant="success" className="mt-3">
//                 {passwordSuccess}
//               </Alert>
//             )}
//             {passwordError && (
//               <Alert variant="danger" className="mt-3">
//                 {passwordError}
//               </Alert>
//             )}
//           </>
//         ) : (
//           <Alert variant="danger">{verificationError}</Alert>
//         )}
//       </Card>
//     </div>
//   );
// };

// export default ResetPassword;
