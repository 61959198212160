import React, { useState, useEffect, useRef, useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { setStartDate, setEndDate, addDateComment } from "../../redux/actions";
import "./ActionPanel.css";

// Format sent to backend
const formatDateForBackend = (date) => {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`;
};

// Display format: DD/MM/YYYY
const formatDisplayDate = (date) => {
  return new Date(date).toLocaleDateString("en-GB");
};

const ETAComponent = ({ itemId, setComments }) => {
  const dispatch = useDispatch();
  const [localStartDate, setLocalStartDate] = useState(null);
  const [localEndDate, setLocalEndDate] = useState(null);
  const isUpdating = useRef(false);

  useEffect(() => {
    if (!itemId) return;

    const fetchTaskDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/items/${itemId}`
        );
        if (response.ok) {
          const data = await response.json();
          const start = data.start_date ? new Date(data.start_date) : null;
          const end = data.end_date ? new Date(data.end_date) : null;
          setLocalStartDate(start);
          setLocalEndDate(end);
          dispatch(setStartDate(start));
          dispatch(setEndDate(end));
        }
      } catch (error) {
        console.error("❌ Error fetching task details:", error);
      }
    };

    fetchTaskDetails();
  }, [itemId, dispatch]);

  const handleDateChange = useCallback(
    async (date, type) => {
      if (!date || isNaN(date.getTime()) || !itemId) return;
      if (isUpdating.current) return;

      isUpdating.current = true;

      const formattedDate = formatDateForBackend(date);
      const endpoint =
        type === "start"
          ? `${process.env.REACT_APP_API_URL}/items/${itemId}/saveStartDate`
          : `${process.env.REACT_APP_API_URL}/items/${itemId}/saveEndDate`;

      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            [type === "start" ? "startDate" : "endDate"]: formattedDate,
            user: localStorage.getItem("username"),
          }),
        });

        if (!response.ok) throw new Error("❌ Failed to update date");

        if (type === "start") {
          setLocalStartDate(date);
          dispatch(setStartDate(date));
        } else {
          setLocalEndDate(date);
          dispatch(setEndDate(date));
        }

        const comment = {
          text: `${
            type === "start" ? "Start" : "End"
          } Date selected : ${formatDisplayDate(date)}`,
          postedBy: "System",
          postedAt: new Date(),
        };

        dispatch(addDateComment(comment));
        if (setComments) setComments((prev) => [...prev, comment]);

        const commentsRes = await fetch(
          `${process.env.REACT_APP_API_URL}/items/${itemId}/comments`
        );
        const updated = await commentsRes.json();
        setComments(updated);
      } catch (error) {
        console.error("❌ Error updating date:", error);
      } finally {
        isUpdating.current = false;
      }
    },
    [itemId, dispatch, setComments]
  );

  return (
    <div className="date-input-wrapper">
      <label>Start Date:</label>
      <DatePicker
        selected={localStartDate}
        onChange={(date) => handleDateChange(date, "start")}
        dateFormat="dd/MM/yyyy"
        className="date-inputs"
      />
      <label>End Date:</label>
      <DatePicker
        selected={localEndDate}
        onChange={(date) => handleDateChange(date, "end")}
        dateFormat="dd/MM/yyyy"
        className="date-inputs"
      />
    </div>
  );
};

export default React.memo(ETAComponent);
