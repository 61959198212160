//forgot password

import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css"; // Uncomment if using Bootstrap

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    try {
      const response = await axios.post("/api/forgotpassword", { email });

      if (response.data.message === "OTP sent successfully to your email") {
        setMessage("✅ OTP has been sent to your email.");
        setTimeout(() => {
          navigate(`/resetpassword/${email}`);
        }, 3000);
      } else {
        setError("❌ Failed to send OTP. Please try again.");
      }
    } catch (err) {
      setError("Error: " + (err.response?.data?.message || "Server error."));
    }
  };

  const wrapperStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    background: "transparent",
  };

  const cardStyle = {
    width: "400px",
    borderRadius: "15px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "white",
    padding: "30px",
    margin: "580px",
  };

  const inputButtonContainerStyle = {
    width: "100%", // ✅ Ensures everything inside has the same width
  };

  const inputStyle = {
    height: "50px",
    fontSize: "1em",
    width: "100%", // ✅ Ensures input width matches button
    boxSizing: "border-box", // ✅ Prevents extra padding from affecting width
  };

  const buttonStyle = {
    height: "50px",
    fontSize: "1em",
    width: "100%", // ✅ Matches input width
    backgroundColor: "#007bff",
    borderColor: "#007bff",
    boxSizing: "border-box", // ✅ Keeps width consistent
  };

  return (
    <div style={wrapperStyle}>
      <div style={cardStyle}>
        <h3 className="text-center mb-4">Forgot Password</h3>
        {message && <p className="text-success text-center">{message}</p>}
        {error && <p className="text-danger text-center">{error}</p>}
        <form onSubmit={handleSubmit} style={inputButtonContainerStyle}>
          <div className="mb-3">
            <label className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={inputStyle} // ✅ Explicit width
            />
          </div>
          <button type="submit" className="btn btn-primary" style={buttonStyle}>
            Request OTP
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;

// import React, { useState } from "react";
// import { Form, Button, Card, Alert } from "react-bootstrap";
// import axios from "axios";

// const ForgotPassword = () => {
//   const [email, setEmail] = useState("");
//   const [error, setError] = useState("");
//   const [success, setSuccess] = useState("");

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setError("");
//     setSuccess("");

//     try {
//       const response = await axios.post("/api/forgotpassword", { email });

//       // Check if the response contains an error message or success message
//       if (response.data.message === "Password reset email sent successfully") {
//         setSuccess("Password reset link sent to your email.");
//       } else {
//         setError("Failed to send password reset link. Please try again.");
//       }
//     } catch (error) {
//       console.log("Error sending reset link:", error);

//       // Error handling based on server response
//       if (error.response) {
//         // The server responded with a status other than 2xx
//         if (error.response.status === 404) {
//           setError("No account found with this email.");
//         } else if (error.response.status === 500) {
//           setError("Server error. Please try again later.");
//         } else {
//           setError("Failed to send password reset link. Please try again.");
//         }
//       } else {
//         // Handle network errors
//         setError("Network error. Please check your connection.");
//       }
//     }
//   };

//   const wrapperStyle = {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     height: "100vh",
//   };

//   const cardStyle = {
//     width: "400px",
//     borderRadius: "15px",
//     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//     margin: "580px",
//   };

//   const inputStyle = {
//     height: "45px",
//     fontSize: "1em",
//   };

//   const buttonStyle = {
//     width: "100%",
//     backgroundColor: "#007bff",
//     borderColor: "#007bff",
//   };

//   return (
//     <div style={wrapperStyle}>
//       <Card style={cardStyle}>
//         <Card.Body>
//           <h3 className="text-center mb-4">Forgot Password</h3>
//           {error && <Alert variant="danger">{error}</Alert>}
//           {success && <Alert variant="success">{success}</Alert>}
//           <Form onSubmit={handleSubmit}>
//             <Form.Group>
//               <Form.Label>Email</Form.Label>
//               <Form.Control
//                 type="email"
//                 placeholder="Enter your email"
//                 onChange={(e) => setEmail(e.target.value)}
//                 required
//                 style={inputStyle}
//               />
//             </Form.Group>
//             <Button type="submit" className="mt-4" style={buttonStyle}>
//               Send Reset Link
//             </Button>
//           </Form>
//         </Card.Body>
//       </Card>
//     </div>
//   );
// };

// export default ForgotPassword;
